/* 5. Section Title */
 .section-title h5 {
     margin-bottom: 28px;
}
 .section-text.section-text-green h1 {
     margin-bottom: 24px;
}
 .section-text-white h5 {
     color: #899dab;
}
 .section-text-green h5 {
     color: #8fb569;
     font-weight: 400;
}
 .section-text-small h5 {
     color: #00b7eb;
     text-transform: uppercase;
}
 .calculate-section .section-text-white h5 {
     color: #b1c5d3;
     font-size: 16px;
     text-transform: uppercase;
     letter-spacing: 2px;
     margin-bottom: 25px;
}
 .section-text-green p {
     color: #899dab;
     padding-right: 90px;
}
 .section-title h1 {
     margin-bottom: 18px;
     line-height: 1.2;
}
 .section-back-icon {
     position: absolute;
     left: 0;
     right: 0;
     margin: auto;
     top: -50px;
}
 .section-text-small h1 {
     font-size: 50px;
}
 .back-icon-left {
     margin: 0;
}
 .back-icon-right {
     margin: inherit;
     left: inherit;
}